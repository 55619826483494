import React, { useState, useEffect } from 'react'
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TableSortLabel,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Pagination,
  Menu,
  MenuItem,
  Link
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import axios from 'axios'
import './userStyle.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import { format, isEqual, parseISO, startOfDay } from 'date-fns'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MailIcon from '@mui/icons-material/Mail'
import CommentIcon from '@mui/icons-material/Comment'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

const TABLE_HEAD = [
  { id: 'tokenName', label: 'Token Name', alignRight: false },
  { id: 'tokenSymbol', label: 'Token Symbol', alignRight: false },
  { id: 'tokenAddress', label: 'Token Address', alignRight: false },
  { id: 'telegramURL', label: 'Telegram URL', alignRight: false },
  { id: 'twitterURL', label: 'Twitter URL', alignRight: false },
  { id: 'isContacted', label: 'Is Contacted', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
]

const UserListToolbar = ({
  filterSymbol,
  onFilterSymbol,
  handleKeyDown,
  selectedDate,
  setSelectedDate,
  showContacted,
  handleContactedClick
}) => {
  return (
    <Stack
      direction='row'
      spacing={2}
      alignItems='center'
      justifyContent='space-between'
      style={{ padding: '20px' }}
    >
      <Stack direction='row' spacing={2} alignItems='center'>
        <TextField
          label='Filter by Token Symbol'
          variant='outlined'
          size='medium'
          value={filterSymbol}
          onChange={onFilterSymbol}
          onKeyDown={handleKeyDown}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label='Filter by Date'
            value={selectedDate}
            onChange={date => setSelectedDate(date)}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>
      <Button
        variant={showContacted ? 'contained' : 'outlined'}
        color='primary'
        onClick={handleContactedClick}
      >
        {showContacted ? 'Show All' : 'Show Contacted'}
      </Button>
    </Stack>
  )
}

const EmailForm = ({ open, onClose, token, handleSendEmail }) => {
  const [ccAddress, setCcAddress] = useState('')
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')

  useEffect(() => {
    // Reset state when token changes
    if (token && token.email) {
      setCcAddress('')
      setSubject('')
      setBody('')
    }
  }, [token])

  const handleSend = () => {
    if (token && token.email) {
      handleSendEmail({
        to: Array.isArray(token.email) ? token.email.join(', ') : token.email,
        cc: ccAddress,
        subject: subject,
        text: body
      })
    } else {
      console.error('Token or token.email is null or undefined')
      // Handle error or provide feedback to the user
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle style={{ background: 'black' }}>Send Email</DialogTitle>
      <DialogContent style={{ background: 'black' }}>
        <TextField
          label='To'
          variant='outlined'
          fullWidth
          margin='normal'
          value={
            token && token.email
              ? Array.isArray(token.email)
                ? token.email.join(', ')
                : token.email
              : ''
          }
          disabled
        />
        <TextField
          label='CC'
          variant='outlined'
          fullWidth
          margin='normal'
          value={ccAddress}
          onChange={e => setCcAddress(e.target.value)}
        />
        <TextField
          label='Subject'
          variant='outlined'
          fullWidth
          margin='normal'
          value={subject}
          onChange={e => setSubject(e.target.value)}
        />
        <TextField
          label='Body'
          variant='outlined'
          fullWidth
          margin='normal'
          multiline
          rows={4}
          value={body}
          onChange={e => setBody(e.target.value)}
        />
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSend} color='primary' variant='contained'>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CommentForm = ({
  open,
  onClose,
  comment,
  setComment,
  handleUpdateComment
}) => {
  const handleUpdate = () => {
    handleUpdateComment(comment)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle style={{ background: 'black', fontSize: '26px' }}>
        Comment
      </DialogTitle>
      <DialogContent style={{ background: 'black' }}>
        <TextField
          label='Comment'
          variant='outlined'
          fullWidth
          margin='normal'
          multiline
          rows={4}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpdate} color='primary' variant='contained'>
          Update Comment
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const User = () => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('tokenName')
  const [filterSymbol, setFilterSymbol] = useState('')
  const [tokens, setTokens] = useState([])
  const [showDetailsDialog, setShowDetailsDialog] = useState(false)
  const [showEmailDialog, setShowEmailDialog] = useState(false)
  const [showCommentDialog, setShowCommentDialog] = useState(false)
  const [selectedToken, setSelectedToken] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [comment, setComment] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenuId, setOpenMenuId] = useState(null)
  const [visitedLinks, setVisitedLinks] = useState({})
  const [showContacted, setShowContacted] = useState(false)

  const getTokenList = async (page = 1, limit = 20) => {
    let url = `${process.env.REACT_APP_BASE_URL}/tokenTool/fetch-tokens?sortBy=contacted&sortOrder=desc&page=${page}&limit=${limit}`

    if (filterSymbol) {
      url += `&filterSymbol=${encodeURIComponent(filterSymbol)}`
    }

    if (selectedDate) {
      url += `&filterDate=${encodeURIComponent(selectedDate.toISOString())}`
    }

    if (showContacted) {
      url += '&showContacted=true'
    }

    try {
      const response = await fetch(url)
      const responseData = await response.json()
      if (response.ok) {
        const list = responseData.data.docs || []
        setTokens(list)
        setTotalPages(responseData.data.totalPages)
        setCurrentPage(responseData.data.page)
      } else {
        console.error('Error fetching token list:', responseData.message)
      }
    } catch (error) {
      console.error('Error fetching token list:', error)
    }
  }

  useEffect(() => {
    getTokenList(currentPage)
    // Load visited links from localStorage on component mount
    const storedVisitedLinks =
      JSON.parse(localStorage.getItem('visitedLinks')) || {}
    setVisitedLinks(storedVisitedLinks)
  }, [currentPage, filterSymbol, selectedDate, showContacted])

  const handleContactedClick = () => {
    setShowContacted(prev => !prev)
    setCurrentPage(1) // Reset to first page when toggling contacted filter
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleFilterBySymbol = event => {
    setFilterSymbol(event.target.value)
    setCurrentPage(1)
  }

  const handleDateChange = date => {
    setSelectedDate(date)
    setCurrentPage(1)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      getTokenList()
    }
  }

  const handleActionClick = (event, token) => {
    setAnchorEl(event.currentTarget)
    setSelectedToken(token)
    setOpenMenuId(token._id)
  }

  const handleActionClose = () => {
    setAnchorEl(null)
    setOpenMenuId(null)
  }

  const handleViewDetails = () => {
    setShowDetailsDialog(true)
    handleActionClose()
  }

  const handleSendEmailDialogOpen = () => {
    setShowEmailDialog(true)
    handleActionClose()
  }

  const handleSendEmailDialogClose = () => {
    setShowEmailDialog(false)
  }

  const handleSendEmail = async ({ to, cc, subject, text }) => {
    const toAddresses = Array.isArray(to) ? to.join(', ') : to

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mail/send`,
        {
          to: toAddresses,
          cc: cc,
          subject: subject,
          text: text
        }
      )
      if (response.status === 200) {
        alert('Email sent successfully')
        handleSendEmailDialogClose()
      } else {
        alert('Failed to send email')
      }
    } catch (error) {
      console.error('Error sending email:', error)
      alert('Error sending email')
    }
  }

  const handleCommentDialogOpen = () => {
    setComment(selectedToken.comments || '')
    setShowCommentDialog(true)
    handleActionClose()
  }

  const handleCommentDialogClose = () => {
    setShowCommentDialog(false)
  }

  const handleUpdateComment = async updatedComment => {
    const updatedToken = { ...selectedToken, comments: updatedComment }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/tokenTool/update-data/${selectedToken._id}`,
        updatedToken
      )
      if (response.status === 200) {
        const updatedTokens = tokens.map(token =>
          token._id === selectedToken._id ? updatedToken : token
        )
        setTokens(updatedTokens)
        alert('Comment updated successfully')
        handleCommentDialogClose()
      } else {
        alert('Failed to update comment')
      }
    } catch (error) {
      console.error('Error updating comment:', error)
      alert('Error updating comment')
    }
  }

  const handleCloseDetailsDialog = () => {
    setShowDetailsDialog(false)
  }

  const handleContactCheckboxChange = async (event, token) => {
    const updatedToken = { ...token, isContacted: event.target.checked }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/tokenTool/update-data/${token._id}`,
        updatedToken
      )
      if (response.status === 200) {
        const updatedTokens = tokens.map(t =>
          t._id === token._id ? updatedToken : t
        )
        setTokens(updatedTokens)
        alert('Token updated successfully')
      } else {
        alert('Failed to update token')
      }
    } catch (error) {
      console.error('Error updating token:', error)
      alert('Error updating token')
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const handleLinkClick = (url, tokenId) => {
    // Open the link in a new tab
    window.open(url, '_blank')

    // Update the visited links state and localStorage
    const updatedVisitedLinks = { ...visitedLinks, [tokenId]: true }
    setVisitedLinks(updatedVisitedLinks)
    localStorage.setItem('visitedLinks', JSON.stringify(updatedVisitedLinks))
  }

  const filteredTokens = tokens.filter(token => {
    const symbolMatch = token.tokenSymbol
      .toLowerCase()
      .includes(filterSymbol.toLowerCase())

    if (!selectedDate) return symbolMatch

    const selectedDateStart = startOfDay(selectedDate)
    const createdAtDate = parseISO(token.createdAt)
    const updatedAtDate = parseISO(token.updatedAt)

    const dateMatch =
      isEqual(startOfDay(createdAtDate), selectedDateStart) ||
      isEqual(startOfDay(updatedAtDate), selectedDateStart)

    return symbolMatch && dateMatch
  })

  return (
    <Page title='Tokens'>
      <Container>
        <Typography variant='h4' sx={{ mb: 5 }}>
          Tokens
        </Typography>
        <Card style={{ background: 'black' }}>
          <UserListToolbar
            filterSymbol={filterSymbol}
            onFilterSymbol={handleFilterBySymbol}
            handleKeyDown={handleKeyDown}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            showContacted={showContacted}
            handleContactedClick={handleContactedClick}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={event =>
                            handleRequestSort(event, headCell.id)
                          }
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTokens.map(row => (
                    <TableRow hover key={row._id} tabIndex={-1}>
                      <TableCell align='left'>{row.tokenName}</TableCell>
                      <TableCell align='left'>{row.tokenSymbol}</TableCell>
                      <TableCell align='left'>
                        {row.tokenAddresses
                          .map(addr => addr.address)
                          .join(', ')}
                      </TableCell>
                      <TableCell align='left'>
                        <Link
                          href='#'
                          onClick={() =>
                            handleLinkClick(
                              row.telegramURL,
                              `telegram_${row._id}`
                            )
                          }
                          sx={{
                            color: visitedLinks[`telegram_${row._id}`]
                              ? 'purple'
                              : 'hotpink',
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          {row.telegramURL}
                        </Link>
                      </TableCell>
                      <TableCell align='left'>
                        <Link
                          href='#'
                          onClick={() =>
                            handleLinkClick(
                              row.twitterURL,
                              `twitter_${row._id}`
                            )
                          }
                          sx={{
                            color: visitedLinks[`twitter_${row._id}`]
                              ? 'purple'
                              : 'hotpink',
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          {row.twitterURL}
                        </Link>
                      </TableCell>
                      <TableCell align='left'>
                        <Checkbox
                          checked={row.isContacted}
                          onChange={event =>
                            handleContactCheckboxChange(event, row)
                          }
                        />
                      </TableCell>
                      <TableCell align='left'>
                        <IconButton
                          aria-label='more'
                          aria-controls={`action-menu-${row._id}`}
                          aria-haspopup='true'
                          onClick={event => handleActionClick(event, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={`action-menu-${row._id}`}
                          anchorEl={anchorEl}
                          keepMounted
                          open={openMenuId === row._id}
                          onClose={handleActionClose}
                          PaperProps={{
                            style: {
                              backgroundColor: 'black'
                            }
                          }}
                        >
                          <MenuItem onClick={handleViewDetails}>
                            <ListItemIcon sx={{ color: 'hotpink' }}>
                              <VisibilityIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>View</ListItemText>
                          </MenuItem>
                          <MenuItem onClick={handleSendEmailDialogOpen}>
                            <ListItemIcon sx={{ color: 'hotpink' }}>
                              <MailIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Mail</ListItemText>
                          </MenuItem>
                          <MenuItem onClick={handleCommentDialogOpen}>
                            <ListItemIcon sx={{ color: 'hotpink' }}>
                              <CommentIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Comment</ListItemText>
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Stack spacing={2} direction='row' justifyContent='center' mt={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color='primary'
            />
          </Stack>
        </Card>
      </Container>

      <Dialog
        open={showDetailsDialog}
        onClose={handleCloseDetailsDialog}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle style={{ background: 'black', fontSize: '26px' }}>
          Token Details
        </DialogTitle>
        <DialogContent style={{ background: 'black' }}>
          {selectedToken && (
            <div>
              <Typography variant='h6'>
                Token Name: {selectedToken.tokenName}
              </Typography>
              <Typography variant='h6'>
                Token Symbol: {selectedToken.tokenSymbol}
              </Typography>
              <Typography variant='h6'>Token Addresses:</Typography>
              <ul>
                {selectedToken.tokenAddresses.map((addr, index) => (
                  <li key={index}>
                    {addr.address} (Source: {addr.chainSource})
                  </li>
                ))}
              </ul>
              <Typography variant='h6'>
                Telegram URL: {selectedToken.telegramURL}
              </Typography>
              <Typography variant='h6'>
                Twitter URL: {selectedToken.twitterURL}
              </Typography>
              <Typography variant='h6'>
                Email: {selectedToken.email || 'N/A'}
              </Typography>
              <Typography variant='h6'>
                Is Contacted: {selectedToken.isContacted ? 'Yes' : 'No'}
              </Typography>
              <Typography variant='h6'>
                Comments: {selectedToken.comments || 'N/A'}
              </Typography>
              <Typography variant='h6'>
                Source: {selectedToken.source || 'N/A'}
              </Typography>
              <Typography variant='h6'>
                Created At:{' '}
                {selectedToken.createdAt
                  ? format(parseISO(selectedToken.createdAt), 'PPpp')
                  : 'N/A'}
              </Typography>
              <Typography variant='h6'>
                Updated At:{' '}
                {selectedToken.updatedAt
                  ? format(parseISO(selectedToken.updatedAt), 'PPpp')
                  : 'N/A'}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ background: 'black' }}>
          <Button onClick={handleCloseDetailsDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <EmailForm
        open={showEmailDialog}
        onClose={handleSendEmailDialogClose}
        token={selectedToken}
        handleSendEmail={handleSendEmail}
      />

      <CommentForm
        open={showCommentDialog}
        onClose={handleCommentDialogClose}
        comment={comment}
        setComment={setComment}
        handleUpdateComment={handleUpdateComment}
      />
    </Page>
  )
}

export default User
