// import axios from '../config'
import axios from 'axios';
import { toaster } from '../utils/toast'

export const backendServices = {
  Get,
  Post,
  Put,
  Delete
}

async function Post (url, params) {
  //
  const header = {
    'api-key':
      '9979fd5e7cb73fc0a207226b7d62c7b43f98a8f27e3f05c5e79bd7d62cb1b0db',
    'content-type': 'application/json',
    'x-auth-token': localStorage.getItem('token')
      ? localStorage.getItem('token')
      : null
  }
  try {
    const response = await axios.post(url, params, { headers: header })
    return response
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        // const history = useNavigate();
        // history('/login');
        localStorage.removeItem('token')
      }
      return toaster(
        `${err.response ? err.response.data.message : 'Something went wrong'}`,
        'error'
      )
    } else {
      return toaster(
        `${err.response ? err.response.data.message : 'Something went wrong'}`,
        'error'
      )
    }
  }
}

async function Get (url) {
  const header = {
    'api-key':
      '9979fd5e7cb73fc0a207226b7d62c7b43f98a8f27e3f05c5e79bd7d62cb1b0db',
    'content-type': 'application/json',
    'x-auth-token': localStorage.getItem('token')
      ? localStorage.getItem('token')
      : null
  }
  try {
    const response = await axios.get(url, { headers: header })
    return response
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        window.location.replace('/')
        // history.push('/login');
        localStorage.removeItem('token')
      }
      return toaster(
        `${err.response ? err.response.data.message : 'Something went wrong'}`,
        'error'
      )
    } else {
      return toaster(
        `${err.response ? err.response.data.message : 'Something went wrong'}`,
        'error'
      )
    }
  }
}

async function Put (url, parameters) {
  //   const history = useNavigate();
  const header = {
    'content-type': 'application/json',
    'x-auth-token': localStorage.getItem('token')
      ? localStorage.getItem('token')
      : null
  }
  try {
    const response = await axios.put(url, parameters, { headers: header })
    return response
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        // history.push('/login');
        localStorage.removeItem('token')
      }
      return toaster(
        `${err.response ? err.response.data.message : 'Something went wrong'}`,
        'error'
      )
    } else {
      return toaster(
        `${err.response ? err.response.data.message : 'Something went wrong'}`,
        'error'
      )
    }
  }
}

async function Delete (url) {
  const token = localStorage.getItem('token')
  const headers = {
    'x-auth-token': token || '',
    'Content-Type': 'application/json'
  }

  try {
    const response = await axios.delete(url, { headers })
    return response.data
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message || 'Something went wrong'
      : error.message || 'No response received from server'
    if (error.response && error.response.status === 401)
      localStorage.removeItem('token')
    throw errorMessage
  }
}
