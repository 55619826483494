import { Box, Grid, Container, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Page from '../components/Page'
import { AppVerifiedUsers } from '../components/_dashboard/app'
import { services } from '../services'

export default function DashboardApp() {
  const [data, setData] = useState({
    totalTokensCount: 0,
    contactedTokensCount: 0,
    todayCreatedTokensCount: 0,
    todayContactedTokensCount: 0
  })

  useEffect(() => {
    async function getTokenStats() {
      const url = `${process.env.REACT_APP_BASE_URL}/tokenTool/token-stats`

      try {
        const response = await services.Get(url)
        if (response.status === 200) {
          const statistics = response.data.data // Access the data object inside the response
          console.log('Fetched token statistics:', statistics)
          setData({
            totalTokensCount: statistics.totalTokensCount,
            contactedTokensCount: statistics.contactedTokensCount,
            todayCreatedTokensCount: statistics.todayCreatedTokensCount,
            todayContactedTokensCount: statistics.todayContactedTokensCount
          })
        }
      } catch (error) {
        console.error('Error fetching token statistics:', error)
      }
    }
    getTokenStats()
  }, [])

  return (
    <Page title='Dashboard | Token Details'>
      <Container maxWidth='xl'>
        <Box sx={{ pb: 5 }}>
          <Typography variant='h4'>Welcome To Blocsys Tools</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppVerifiedUsers name='Total Tokens' count={data.totalTokensCount} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppVerifiedUsers
              name='Total Contacted'
              count={data.contactedTokensCount}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppVerifiedUsers
              name='Today Created Tokens'
              count={data.todayCreatedTokensCount}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppVerifiedUsers
              name='Today Contacted Tokens'
              count={data.todayContactedTokensCount}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}