import { Navigate, useRoutes, useNavigate } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";

import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import User from "./pages/User";
import Emails from "./pages/emails"

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <DashboardApp /> },
        { path: "user", element: <User /> },
        { path: "emails", element: <Emails /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [{ path: "/", element: <Login /> }],
    },
  ]);
}
