import React, { useState, useEffect } from 'react'
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TableSortLabel,
  TextField,
  Pagination,
  Stack,
  Button // Add Button component
} from '@mui/material'
import axios from 'axios'
import './userStyle.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import { format, parseISO, startOfDay, isEqual } from 'date-fns' // Importing isEqual

const TABLE_HEAD = [
  { id: 'tokenName', label: 'Token Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false }
]

const UserListToolbar = ({
  filterSymbol,
  onFilterSymbol,
  handleKeyDown,
  selectedDate,
  setSelectedDate,
  onDownloadCsv
}) => {
  // Add onDownloadCsv prop
  return (
    <Stack direction='row' spacing={2} style={{ padding: '20px' }}>
      <TextField
        label='Filter by Token Name'
        variant='outlined'
        size='medium'
        value={filterSymbol}
        onChange={onFilterSymbol}
        onKeyDown={handleKeyDown}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label='Filter by Date'
          value={selectedDate}
          onChange={date => setSelectedDate(date)}
          renderInput={params => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Button variant='contained' color='primary' onClick={onDownloadCsv}>
        Download CSV
      </Button>{' '}
      {/* Add download button */}
    </Stack>
  )
}

const User = () => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('tokenName')
  const [filterSymbol, setFilterSymbol] = useState('')
  const [tokens, setTokens] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0) // Track total items for pagination

  const getTokenList = async (page = 1, limit = 20) => {
    let url = `${process.env.REACT_APP_BASE_URL}/tokenTool/fetch-mails?page=${page}&limit=${limit}`

    if (filterSymbol) {
      url += `&filterSymbol=${encodeURIComponent(filterSymbol)}`
    }
    if (selectedDate) {
      const formattedDate = format(startOfDay(selectedDate), 'yyyy-MM-dd')
      url += `&date=${formattedDate}`
    }

    try {
      const response = await fetch(url)
      const responseData = await response.json()
      if (response.ok) {
        const list = responseData.tokens || []
        setTokens(list)
        setTotalPages(responseData.totalPages)
        setCurrentPage(responseData.page)
        setTotalItems(responseData.totalItems)
      } else {
        console.error('Error fetching token list:', responseData.message)
      }
    } catch (error) {
      console.error('Error fetching token list:', error)
    }
  }

  useEffect(() => {
    getTokenList(currentPage)
  }, [currentPage, filterSymbol, selectedDate]) // Fetch new data when filters or page changes

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleFilterBySymbol = event => {
    setFilterSymbol(event.target.value)
    setCurrentPage(1) // Reset to first page when filter changes
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      getTokenList(1) // Fetch data for the first page on Enter key
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const filteredTokens = tokens.filter(token => {
    const symbolMatch = token.tokenName
      .toLowerCase()
      .includes(filterSymbol.toLowerCase())

    if (!selectedDate) return symbolMatch

    const selectedDateStart = startOfDay(selectedDate)
    const createdAtDate = parseISO(token.createdAt)

    const dateMatch = isEqual(startOfDay(createdAtDate), selectedDateStart)

    return symbolMatch && dateMatch
  })

  const handleDownloadCsv = () => {
    const emails = filteredTokens
      .filter(row => row.email) // Ensure there's an email field
      .flatMap(row => (Array.isArray(row.email) ? row.email : [row.email])) // Flatten arrays of emails

    if (emails.length === 0) {
      alert('No emails are present in this date')
      return
    }

    const csvContent = emails.join('\n')
    const csvHeader = 'Email'
    const csvString = [csvHeader, csvContent].join('\n')

    const blob = new Blob([csvString], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'filtered_emails.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <Page title='Tokens'>
      <Container>
        <Typography variant='h4' sx={{ mb: 5 }}>
          Tokens
        </Typography>
        <Card style={{ background: 'black' }}>
          <UserListToolbar
            filterSymbol={filterSymbol}
            onFilterSymbol={handleFilterBySymbol}
            handleKeyDown={handleKeyDown}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onDownloadCsv={handleDownloadCsv} // Pass download handler
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={event =>
                            handleRequestSort(event, headCell.id)
                          }
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTokens.map(row => (
                    <TableRow hover key={row._id} tabIndex={-1}>
                      <TableCell align='left'>{row.tokenName}</TableCell>
                      <TableCell align='left'>
                        {Array.isArray(row.email)
                          ? row.email.join(', ')
                          : row.email}
                      </TableCell>
                      <TableCell align='left'>
                        {row.createdAt
                          ? format(parseISO(row.createdAt), 'PPpp')
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Stack spacing={2} direction='row' justifyContent='center' mt={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color='primary'
              // Only show pagination if there are more than one page
              showFirstButton
              showLastButton
              disabled={totalPages <= 1}
            />
          </Stack>
        </Card>
      </Container>
    </Page>
  )
}

export default User
